import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';

import { SessionService } from '@app/core/services/session.service';

import { environment } from '@environments/environment';
import { switchMap, take } from 'rxjs/operators';

import moment from 'moment';
import { Result } from '@app/shared/finance-forms/models/result.model';

@Injectable()
export class SoftSearchService {
	private APIUrl: string = environment.api_url;
	private token: string;

	SoftSearch$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

	constructor(private httpClient: HttpClient, private session_service: SessionService) {}

	public getAuthToken(): Observable<any> {
		const headers = new HttpHeaders({
			Domain: this.session_service.domain,
		});

		return this.httpClient.post(`${this.APIUrl}soft-credit-checker/auth`, null, { headers });
	}

	public getOptions(vehicle_id: string): Observable<any> {
		const headers = new HttpHeaders({
			Domain: this.session_service.domain,
		});

		let params = new HttpParams();
		params = params.append('vehicle_id', vehicle_id);
		params = params.append('soft_search', true);

		return this.httpClient.get(`${this.APIUrl}finance-application`, { headers, params });
	}

	public getResult(soft_search_reference: string): Observable<any> {
		return this.getAuthToken().pipe(
			take(1),
			switchMap((response: { token: string; expires: string }) => {
				const headers: HttpHeaders = new HttpHeaders({
					Domain: this.session_service.domain,
					Authorization: 'Bearer ' + response.token,
				});

				let params = new HttpParams();
				params = params.append('soft_search_reference', soft_search_reference);

				return this.httpClient.get(`${this.APIUrl}soft-credit-checker`, { headers, params });
			})
		);
	}

	public setSoftSearch(softSearch: any) {
		this.SoftSearch$.next(softSearch);
	}

	public submitSoftSearch(data, value, vehicle_id) {
		return this.getAuthToken().pipe(
			take(1),
			switchMap((response: { token: string; expires: string }) => {
				const headers: HttpHeaders = new HttpHeaders({
					Domain: this.session_service.domain,
					Authorization: 'Bearer ' + response.token,
				});

				const url: string = `${this.APIUrl}soft-credit-checker`;

				const address_history: object[] = [];

				value.your_address.address_history.forEach((address) => {
					address_history.push({
						subbuilding: address.subbuilding,
						number: address.namenumber ? address.namenumber : null,
						street: address.street,
						town: address.towncity,
						postcode: address.postcode,
						county: address.county,
						tenure: address.accommodation_type,
						duration: {
							months: Number(address.months_at_address),
							years: Number(address.years_at_address),
						}
					});
				});

				const dob: { day: number, month: number, year: number } = {
					day: moment(value.more_details.dob, 'YYYY-MM-DD').date(),
					month: moment(value.more_details.dob, 'YYYY-MM-DD').month() + 1,
					year: moment(value.more_details.dob, 'YYYY-MM-DD').year()
				};

				return this.httpClient.post(
					url,
					{
						title: value.your_name.title,
						first_name: value.your_name.first_name,
						last_name: value.your_name.last_name,
						email: value.more_details.email,
						phone: value.more_details.mob_tel,
						dob,
						addresses: address_history,
						vehicle_id: vehicle_id,
					},
					{ headers: headers }
				);
			})
		);
	}

	public submitMoreInformation(value, result: Result) {
		return this.getAuthToken().pipe(
			take(1),
			switchMap((response: { token: string; expires: string }) => {
				const headers: HttpHeaders = new HttpHeaders({
					Domain: this.session_service.domain,
					Authorization: 'Bearer ' + response.token,
				});

				const url: string = `${this.APIUrl}soft-credit-checker/more-information`;

				return this.httpClient.post(
					url,
					{
						reference: result.reference,
						quote_id: result.soft_search.quote_id,
						employment_status: value.more_information.employment_status,
						driving_license: value.more_information.driving_license,
						net_monthly: value.more_information.net_monthly,
						payslips: value.more_information.payslips,
					},
					{ headers: headers }
				);
			})
		);
	}

	public checkEmail(soft_search_reference: string, email: string) {
		return this.getAuthToken().pipe(
			take(1),
			switchMap((response: { token: string; expires: string }) => {
				const headers: HttpHeaders = new HttpHeaders({
					Domain: this.session_service.domain,
					Authorization: 'Bearer ' + response.token,
				});

				return this.httpClient.post(
					`${this.APIUrl}soft-credit-checker/verify`,
					{
						soft_search_reference: soft_search_reference,
						email: email,
					},
					{ headers: headers }
				);
			})
		);
	}
}
