import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgxPageScrollCoreModule, PageScrollService } from 'ngx-page-scroll-core';

import { UIDealerHeaderModule } from '@app/ui/components/dealer-header/dealer-header.module';
import { UIContactListModule } from '@app/ui/components/contact-list/contact-list.module';
import { UIFormsModule } from '@app/ui-forms/ui-forms.module';

import { AuthenticatePassThroughComponent } from '@app/core/components/auth/authenticate-pass-through/authenticate-pass-through.component';
import { AuthenticateComponent } from '@app/core/components/auth/authenticate/authenticate.component';
import { InvalidateComponent } from '@app/core/components/auth/invalidate/invalidate.component';
import { NotFoundComponent } from '@app/core/components/not-found/not-found.component';
import { ToastsComponent } from '@app/core/components/toasts/toasts.component';
import { ErrorComponent } from '@app/core/components/error/error.component';
import { StartComponent } from '@app/core/components/start/start.component';
import { ModalComponent } from '@app/core/components/modal/modal.component';

import { DealerContactModalComponent } from '@app/core/modal-components/dealer-contact/dealer-contact.component';
import { DealerMapModalComponent } from '@app/core/modal-components/dealer-map/dealer-map.component';
import { ConfirmModalComponent } from '@app/core/modal-components/confirm/confirm.component';

import { FinanceManagerAPIService } from '@app/finance-manager/services/finance-manager-api.service';
import { IFrameCommunicationsService } from '@app/core/services/iframe-communications.service';
import { FinanceManagerService } from '@app/finance-manager/services/finance-manager.service';
import { DemonstrationService } from '@app/configure/services/demonstration.service';
import { ConfigureAPIService } from '@app/configure/services/configure-api.service';
import { SoftSearchService } from '@app/soft-search/services/soft-search.service';
import { SkipFinanceService } from '@app/configure/services/skip-finance.service';
import { ScriptLoaderService } from '@app/core/services/script-loader.service';
import { ConfigureService } from '@app/configure/services/configure.service';
import { TrackingService } from '@app/core/services/tracking.service';
import { CoreAPIService } from '@app/core/services/core-api.service';
import { StorageService } from '@app/core/services/storage.service';
import { SessionService } from '@app/core/services/session.service';
import { APIUtilityService } from './services/api-utility.service';
import { PusherService } from '@app/core/services/pusher.service';
import { HelperService } from '@app/core/services/helper.service';
import { LoqateService } from '@app/core/services/loqate.service';
import { ModalService } from '@app/core/services/modal.service';
import { ErrorService } from '@app/core/services/error.service';
import { ToastService } from '@app/core/services/toast.service';
import { APIService } from '@app/core/services/api.service';

import { EnquiryAuthenticateResolver } from '@app/core/resolvers/enquiry-authenticate.resolver';
import { FinanceManagerEnquiryResolver } from './resolvers/finance-manager-enquiry.resolver';
import { ConfigureVehicleResolver } from '@app/core/resolvers/configure-vehicle.resolver';
import { ConfigureEnquiryResolver } from '@app/core/resolvers/configure-enquiry.resolver';
import { DealerResolver } from '@app/core/resolvers/dealer.resolver';
import { Resolvers } from '@app/core/resolvers/resolvers';

import { PAGE_SCROLL_CONFIG, easingLogic } from './consts/scroll.consts';
import { DataLayerService } from './services/data-layer.service';

@NgModule({
	declarations: [
		AuthenticatePassThroughComponent,
		AuthenticateComponent,
		InvalidateComponent,
		NotFoundComponent,
		ToastsComponent,
		ErrorComponent,
		StartComponent,
		ModalComponent,

		DealerContactModalComponent,
		DealerMapModalComponent,
		ConfirmModalComponent,
	],
	imports: [
		RouterModule,
		CommonModule,
		HttpClientModule,
		NgxPageScrollCoreModule.forRoot({
			duration: PAGE_SCROLL_CONFIG.duration,
			scrollOffset: PAGE_SCROLL_CONFIG.scrollOffset,
			easingLogic,
		}),
		UIFormsModule,
		UIContactListModule,
		UIDealerHeaderModule,
	],
	providers: [
		PageScrollService,

		IFrameCommunicationsService,
		FinanceManagerAPIService,
		FinanceManagerService,
		SkipFinanceService,
		DemonstrationService,
		ScriptLoaderService,
		ConfigureAPIService,
		APIUtilityService,
		SoftSearchService,
		DataLayerService,
		ConfigureService,
		TrackingService,
		CoreAPIService,
		StorageService,
		SessionService,
		PusherService,
		HelperService,
		LoqateService,
		ToastService,
		ErrorService,
		ModalService,
		APIService,

		FinanceManagerEnquiryResolver,
		EnquiryAuthenticateResolver,
		ConfigureVehicleResolver,
		ConfigureEnquiryResolver,
		DealerResolver,
		Resolvers,
	],
	exports: [ToastsComponent, ErrorComponent],
})
export class CoreModule {}
